/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
import {
  put,
  call,
  fork,
  all,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import fetch from 'isomorphic-fetch';
import * as actions from '../actions';
import { history } from '../store';
import { isEmpty, findByKeyVal } from '../utils/find';

const url = process.env.REACT_APP_API_URL;

const getApi = path =>
  fetch(`${url}/${path}.json`, {
    credentials: 'include',
  })
    .then(response => response.json())
    .catch(error => {
      console.log(`get request failed ${error}`);
    });

const postApi = (path, body) =>
  fetch(`${url}/${path}.json`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : {},
  })
    .then(response => response.json())
    .catch(error => {
      console.log(`post request failed ${error}`);
    });

const putApi = (path, body) =>
  fetch(`${url}/${path}.json`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : {},
  })
    .then(response => response.json())
    .catch(error => {
      console.log(`put request failed ${error}`);
    });

const deleteApi = path =>
  fetch(`${url}/${path}.json`, {
    method: 'DELETE',
    credentials: 'include',
  }).catch(error => {
    console.log(`delete request failed ${error}`);
  });

const forwardTo = location => history.push(location);

export function* fetchProducts() {
  const products = yield call(getApi, 'products');
  if (products) {
    products.products.forEach(category => {
      category.categories.forEach(group => {
        if (group.products.filter(product => !product.invisible).length === 0) {
          group.invisible = true;
        } else {
          group.invisible = false;
        }
      });
      if (
        category.categories.filter(filterGroup => !filterGroup.invisible)
          .length === 0
      ) {
        category.invisible = true;
      } else {
        category.invisible = false;
      }
    });
    yield put(actions.receiveProducts(products));
  }
}

export function* fetchTime() {
  const time = yield call(getApi, 'current_time');
  yield put(actions.receiveTime(time));
}

export function* fetchSettings() {
  const settings = yield call(getApi, 'settings');
  yield put(actions.receiveSettings(settings));
  // fetch time into settings
  const time = yield call(getApi, 'current_time');
  yield put(actions.receiveTime(time));
}

export function* fetchAddress() {
  const address = yield call(getApi, 'streets');
  yield put(actions.receiveAddress(address));
}

export function* postKazanOrder(action) {
  const result = yield call(postApi, 'manual_orders', action.payload);
  yield put(actions.receiveKazanOrderResult(result));
}

const postLogIn = (path, body) =>
  fetch(`${url}/${path}.json`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : {},
  })
    .then(response =>
      // if(!response.ok) {
      // throw new Error(`${response.status}, ${response.statusText}`);
      // }
      response.json()
    )
    .then(result => result)
    .catch(error => {
      console.log(error);
      return 'error';
    });

export function* signIn(action) {
  const result = yield call(postLogIn, 'users/sign_in', action.payload);
  // console.log('signIn result >', result);
  if (
    result === 'error' ||
    result.error ||
    (result.errors && result.errors.length !== 0)
  ) {
    let resultErrors;
    if (result.errors) {
      resultErrors = result.errors;
    } else if (result.error) {
      resultErrors = result.error;
    } else {
      resultErrors = '';
    }
    // console.log('signIn resultErrors >', resultErrors);
    yield put(actions.logInError({ isError: true, errors: resultErrors }));
  } else {
    yield put(actions.signIn(result));
    yield put(actions.logInError({ isError: false, errors: '' }));
    yield call(forwardTo, '/profile');
  }
}

export function* signOut(action) {
  const redirectPath = action.payload.path || '/';
  const protectedPaths = ['/profile'];
  yield call(deleteApi, 'users/sign_out', action.payload);
  yield put(actions.signOut());
  yield call(
    forwardTo,
    protectedPaths.indexOf(redirectPath) > -1 ? '/' : redirectPath
  );
}

export function* signUp(action) {
  const result = yield call(postLogIn, 'users', action.payload);
  if (
    result === 'error' ||
    result.error ||
    (result.errors && result.errors.length !== 0)
  ) {
    let resultErrors;
    if (result.errors) {
      resultErrors = result.errors;
    } else if (result.error) {
      resultErrors = result.error;
    } else {
      resultErrors = '';
    }
    yield put(actions.logInError({ isError: true, errors: resultErrors }));
  } else {
    yield put(actions.signUp(result));
    yield put(actions.logInError({ isError: false, errors: '' }));
  }
}

// export function* signUpPhone(action) {
//   const result = yield call(signUpPhoneApi, action.payload);
//   yield put(actions.signUp(result));
// }

export function* forgotPassword(action) {
  const result = yield call(postLogIn, 'users/password', action.payload);
  if (
    result === 'error' ||
    result.error ||
    (result.errors && result.errors.length !== 0)
  ) {
    let resultErrors;
    if (result.errors) {
      resultErrors = result.errors;
    } else if (result.error) {
      resultErrors = result.error;
    } else {
      resultErrors = '';
    }
    yield put(actions.logInError({ isError: true, errors: resultErrors }));
  } else {
    yield put(actions.forgotPassword(result));
    yield put(actions.logInError({ isError: false, errors: '' }));
  }
}

export function* currentUser() {
  const result = yield call(getApi, 'current_user');
  if (result) {
    yield put(actions.currentUser(result));
  }
}

export function* fetchManualProducts() {
  const products = yield call(getApi, 'manual_products');
  yield put(actions.receiveManualProducts(products));
}

export function* sendPersonalData(action) {
  const result = yield call(putApi, 'current_user', action.payload);
  yield put(actions.savePersonalData(result));
}

export function* sendAddresses(action) {
  const result = yield call(putApi, 'current_user', action.payload);
  yield put(actions.saveAddresses(result));
}

export function* verifyCode(action) {
  const result = yield call(
    putApi,
    `orders/${action.payload.orderId}/cash_verify_sms`,
    { code: action.payload.code }
  );
  // todo check result code 200
  try {
    if (result && result.valid_code) {
      yield put(
        actions.toggleSmsConfirmModal({
          isShow: false,
        })
      );
      yield put(push('/order_complete'));
      yield put(actions.clearBasket());
    } else {
      yield put(
        actions.toggleSmsConfirmModal({
          isShow: true,
          orderId: result.id,
          displayPhone: action.payload.phone,
          isError: true,
        })
      );
      throw result;
    }
  } catch (error) {
    console.log('sms code verification ERROR ', error);
  }
}

export function* resendCode(action) {
  const result = yield call(
    postApi,
    `orders/${action.payload.orderId}/cash_verify_sms`,
    action.payload
  );
  if (result.id) {
    yield put(
      actions.toggleSmsConfirmModal({
        isShow: true,
        orderId: result.id,
        displayPhone: action.payload.phone,
        updateCode: true,
      })
    );
  }
}

// @basket arg is empty by default not to check basket- and promo products
export function* verifyPromoProducts(promo, basket = {}) {
  if (promo && promo.products && !promo.promo_code_invalid) {
    const state = yield select();
    const { products } = promo;

    if (
      state &&
      state.rootReducer.productsReducer.products &&
      isEmpty(basket)
    ) {
      const stateProducts = state.rootReducer.productsReducer.products;

      for (let index = 0; index < Object.keys(products).length; index += 1) {
        const item = products[Object.keys(products)[index]];
        if (item && item.iiko_guid) {
          yield put(
            actions.addToBasket(
              findByKeyVal(stateProducts, 'iiko_guid', item.iiko_guid)
            )
          );
        }
      }
    }

    if (!isEmpty(basket)) {
      const basketProducts = [...basket.products.values()];

      for (let index = 0; index < Object.keys(products).length; index + 1) {
        const item = products[Object.keys(products)[index]];
        if (
          basketProducts &&
          basketProducts.length > 0 &&
          item &&
          item.iiko_guid
        ) {
          const isInBasket = findByKeyVal(basketProducts, 'iiko_guid', item.iiko_guid);
          if (isInBasket) {
            // console.log('ITEM IN BASKET, OK', item);
          } else {
            // console.log('ITEM NOT IN BASKET, CLEAR PROMO', item);
            yield put(actions.receivePromo({}));
          }
        }
      }
    }
  }
}

export function* checkPromo(action) {
  const promo = yield call(postApi, 'promo_check', action.payload);
  yield put(actions.receivePromo(promo));
  // manage products received in promo code
  if (promo && !promo.promo_code_invalid) {
    yield verifyPromoProducts(promo);
  }
}

export function* payanywayResult(action) {
  if (action.payload) {
    yield put(
      actions.togglePayanywayModal({
        isShow: false,
        iframeUrl: '',
      })
    );
    yield put(push('/order_complete'));
    yield put(actions.clearBasket());
  } else {
    yield put(
      actions.togglePayanywayModal({
        isShow: false,
        iframeUrl: '',
      })
    );
  }
}

export function* verifyBasket(action) {
  const state = yield select();
  if (state && state.rootReducer.basketReducer.basket) {
    const { basket, promo } = state.rootReducer.basketReducer;
    const getAmount = basket.amount;
    if (getAmount && getAmount === 0) {
      yield put(actions.clearBasket());
    }
    yield verifyPromoProducts(promo, basket);
  }
}

export function* makeOrders(action) {
  const result = yield call(postApi, 'orders', action.payload);

  if (!result) {
    return;
  }

  if (result.errors) {
    console.log('order not sent: check for errors');
    // todo visual form errors

    if (result.errors.promo_code) {
      console.log('(!) promocode error');
    }
    if (result.errors.delivery_time) {
      console.log('(!) delivery time error');
    }
    if (result.errors.address_street) {
      console.log('(!) address error');
    }
    if (result.errors.product_balance) {
      const data = {
        message_data: `Ваш заказ не отправлен. Некоторые блюда в стоп-листе.`,
        message_type: 'error',
        message_id: 'make_order_error',
      }
      yield put(actions.sendNotification(data));
      console.log('(!) product error');
    }
    if (result.errors.payment_type) {
      // The phone number is blocked for sending sms messages
      yield put(actions.showPaymentError());
      console.log('(!) payment type error');
    }
  } else if (result.orderamount === '0.00' || result.amount === '0.00') {
    yield put(push('/order_complete'));
    yield put(actions.clearBasket());
  } else if (action.payload.paymentType === 'cash') {
    const user = yield call(getApi, 'current_user');
    if (user && user.account_type === 'operator') {
      yield put(push('/order_complete'));
      yield put(actions.clearBasket());
    } else {
      yield put(
        actions.toggleSmsConfirmModal({
          isShow: true,
          orderId: result.id,
          displayPhone: action.payload.phone,
        })
      );
    }
  } else if (action.payload.paymentType === 'card') {
    const src = [];
    Object.keys(result).map(objectKey => {
      src.push(`${objectKey}=${result[objectKey]}`);
      return objectKey;
    });

    const iframeUrl = `${
      process.env.REACT_APP_API_URL
    }/payanyway/applepay?${src.join('&')}`;
    yield put(
      actions.togglePayanywayModal({
        isShow: true,
        iframeUrl,
      })
    );
  } else {
    const src = [];
    Object.keys(result).map(objectKey =>
      src.push(`MNT_${objectKey.toUpperCase()}=${result[objectKey]}`)
    );

    src.push(`additionalParameters.ownerLogin=7${action.payload.phone}`);

    if (
      action.payload.paymentType === 'card' ||
      action.payload.paymentType === '510801' ||
      action.payload.paymentType === '775856' ||
      action.payload.paymentType === '1015'
    ) {
      src.push(`paymentSystem.unitId=${action.payload.paymentType}`);
      src.push(`paymentSystem.limitIds=${action.payload.paymentType}`);
    }
    const iframeUrl = `https://www.moneta.ru/assistant.widget?followup=true&javascriptEnabled=true&${src.join(
      '&'
    )}`;
    yield put(
      actions.togglePayanywayModal({
        isShow: true,
        iframeUrl,
      })
    );
  }
  if (result && result.id) {
    const orderData = yield call(getApi, `orders/${result.id}`);
    if (orderData) {
      yield put(actions.receiveOrder(orderData));
    }
  }
}

export function* watchManulProducts() {
  yield takeEvery('GET_MANUAL_PRODUCTS', fetchManualProducts);
}

export function* watchOrderKazan() {
  yield takeEvery('ORDER_KAZAN', postKazanOrder);
}

export function* watchTime() {
  yield takeEvery('FETCH_TIME', fetchTime);
}

export function* watchAuth() {
  yield [
    takeEvery('CALL_SIGN_IN', signIn),
    takeEvery('CALL_SIGN_UP', signUp),
    takeEvery('CALL_SIGN_OUT', signOut),
    // takeEvery('CALL_SIGN_UP_PHONE', signUpPhone),
    takeEvery('CALL_SIGN_UP_PHONE', signUp),
    takeEvery('CALL_FORGOT_PASSWORD', forgotPassword),
    takeEvery('CALL_CURRENT_USER', currentUser),
  ];
}

export function* watchProfile() {
  yield [
    takeEvery('SEND_PERSONAL_DATA', sendPersonalData),
    takeEvery('SEND_ADDRESSES', sendAddresses),
    takeLatest('MAKE_ORDER', makeOrders),
    takeEvery('VERIFY_CODE', verifyCode),
    takeEvery('RESEND_CODE', resendCode),
    takeEvery('CHECK_PROMO', checkPromo),
    takeEvery('PAYANYWAY_RESULT', payanywayResult),
  ];
}

export function* watchBasket() {
  yield [
    takeEvery('SUB_FROM_BASKET', verifyBasket),
    takeEvery('REMOVE_FROM_BASKET', verifyBasket),
  ];
}

export function* startup() {
  yield fork(fetchSettings);
  // yield fork(fetchTime);
  yield fork(fetchProducts);
  yield fork(fetchAddress);
}

//
export default function* root() {
  yield fork(startup);
  yield all([
    call(watchManulProducts),
    call(watchOrderKazan),
    call(watchAuth),
    call(watchProfile),
    call(watchBasket),
    call(watchTime),
  ]);
}
